<template>
  <v-container>
    
    <v-form ref="form" v-model="valid">
      <v-card
        class="pa-md-4 mx-lg-auto"
        outlined
      >
        <v-row>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="postForm.nik"
              :rules="[rules.maxnik]"
              label="NIK/NIP"
              type="number"
              counter="20"
            ></v-text-field>  
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="postForm.fullname"
               :rules="[rules.required]"
              label="Fullname"
            ></v-text-field>  
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="postForm.username"
               :rules="[rules.required]"
              label="Username"
            ></v-text-field>  
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              :disabled="isEdit"
              v-model="myemail"
               :rules="[rules.emailRules]"
              label="Email"
            ></v-text-field>  
          </v-col>

           <v-col
            cols="12"
            md="6"
          >
            <v-text-field
                v-model="password"
                label="Password"
                :type="showPass ? 'text' : 'password'"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPass = !showPass"
                :rules="[]"
            ></v-text-field>
          </v-col>

           <v-col
            cols="12"
            md="6"
          >
          <v-text-field
                v-model="passwordAgain"
                label="Password (Again)"
                :type="showPass ? 'text' : 'password'"
                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPass = !showPass"
                :rules="[passwordConfirmationRule]"
            ></v-text-field>

          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-switch
              v-model="postForm.confirmed"
              label="Confirmed"
            ></v-switch>
          </v-col>

           <v-col
            cols="12"
            md="6"
          >
            <v-switch
              v-model="postForm.blocked"
              label="Blocked"
            ></v-switch> 
          </v-col>

          <v-col
            cols="12"
            md="6"
          >
            <v-dialog
                ref="SelBDate"
                v-model="menuSelbdate"
                :return-value.sync="postForm.birthdate"
                persistent
                width="290px"
              >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="postForm.birthdate"
                  label="Birthday"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
                <v-date-picker 
                v-model="postForm.birthdate"
                >
                <v-spacer></v-spacer>
                  <v-btn text color="primary" @click="menuSelbdate = false">Cancel</v-btn>
                  <v-btn text color="primary" @click="$refs.SelBDate.save(postForm.birthdate)">OK</v-btn>
                </v-date-picker>
                
            </v-dialog>
            
          </v-col>

           <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="postForm.phoneNumber"
              label="Mobile Phone"
              type="number"
            ></v-text-field>  
          </v-col>

           <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="postForm.phoneDesk"
              label="PhoneDesk"
              type="number"
            ></v-text-field>  
          </v-col>

          <v-col
          cols="12"
            md="6"
          >
            <v-select
            :items="listRole"
            name="value"
            label="Pilih Role"
            v-model="selectRole"
            item-text="text"
            ></v-select>
      
          </v-col>

          <v-col
          cols="12"
            md="6"
          >
            <v-select
            :items="listGender"
            name="value"
            label="Pilih Jenis Kelamin"
            v-model="selectGender"
            item-text="text"
            ></v-select>
      
          </v-col>


          <v-col
            cols="12"
            md="12"
          >
            <v-file-input
              v-model="attachment.file"
              placeholder="Upload Foto ID Card"
              label="File input"
              show-size
              single
              prepend-icon="mdi-paperclip"
              accept="image/*"
              @change="onFileChange"
            >
              <template v-slot:selection="{ text }">
                <v-chip
                  small
                  label
                  color="primary"
                >
                  {{ text }}
                </v-chip>
              </template>
            </v-file-input>
            <div class="grey lighten-1 pa-md-2 mx-lg-auto"><v-img v-if="attachment.url != ''" :src="attachment.url" max-height="300px" aspect-ratio="1.7"></v-img></div>
          </v-col>

          <v-divider></v-divider>
          
          <v-col
            cols="12"
            md="6"
          >
            <v-switch
              v-model="postForm.hasEsign"
              label="hasEsign"
              outlined
            ></v-switch>
          </v-col>

           <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="postForm.nik_esign"
              label="NIK ESign (KTP/NIP) Pegawai"
              outlined
            ></v-text-field>  
          </v-col>
        </v-row>
        <v-row>
          <v-col>

            <v-btn
              color="warning"
              class="mr-4"
              @click="resetForm"
            >
              Reset Form
            </v-btn>
            <v-btn
              color="error"
              class="mr-4"
              @click="goBack()"
            >
              Batal
            </v-btn>

            <v-btn v-if="!isEdit" :disabled="!valid" 
              color="primary"
              class="mr-4"
              @click="saveAddForm()"
            >
              Simpan
            </v-btn>
            <v-btn v-else :disabled="!valid" 
              color="primary"
              class="mr-4"
              @click="saveEditForm()"
            >
              Ubah
            </v-btn>
      
          </v-col>
        </v-row>
      </v-card>
    </v-form>
    <v-snackbar
        v-model="toast.show"
        :timeout="toast.timeout"
      >
        {{ toast.text }}
        <v-btn
          :color="toast.color"
          text
          @click="toast.show = false"
        >
          Close
        </v-btn>
      </v-snackbar>

      <v-dialog v-model="isLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line centered>
                    <v-list-item-content>
                      <div class="overline mb-4">Processing</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                          indeterminate
                          color="yellow darken-2"
                        ></v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

      <v-dialog v-model="isupLoading" fullscreen>
          <v-container fluid fill-height style="background-color: rgba(0, 0, 0, 0.8);">
              <v-layout justify-center align-center>
                <v-card
                  width="70vW"
                  class="pa-md-2 mx-lg-auto"
                  outlined
                >
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="overline mb-4">Uploading File</div>
                      <v-list-item-title class="headline mb-1">Please Wait....</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-progress-linear
                            v-model="uploadPercentage"
                            height="25"
                            reactive
                          >
                          <strong>{{ Math.ceil(uploadPercentage) }}%</strong>
                        </v-progress-linear>
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>

              </v-layout>
          </v-container>
      </v-dialog>

      <v-dialog v-model="dialogConfirm" persistent max-width="350">
        <v-card>
          <v-card-title class="headline">Konfirmasi</v-card-title>
          <v-card-text>Apakah anda yakin data tersebut sudah benar?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning darken-1" text @click="dialogConfirm = false">Batal</v-btn>
            <v-btn v-if="!isEdit" color="blue darken-1" @click="insertData()">Submit</v-btn>
            <v-btn v-else color="blue darken-1" @click="updateData()">Submit</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
</v-container>
</template>

<script>
import { insertUser, getUserByID, updateUser, uploadAvatarUser, getListRole } from '@/api/admin'
import { getSettingParamEofficeData, updateSettingParam } from '@/api/settingparam'
import { _GLOBAL } from '@/api/server'
import moment from 'moment'
import axios from 'axios'

const defaultForm = {
  nik: '',
  fullname: '',
  birthdate: '',
  phoneNumber: '',
  phoneDesk:'',
  username: '',
  email: '',
  password: '',
  confirmed: false,
  blocked: false,
  role: '',
  avatar: '',
  gender: 'Pria',
  hasEsign: 0,
  nik_esign: ''
}

export default {
  name: 'FormUsers',
  components: {
  },
  props: {
    isEdit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      postForm: Object.assign({}, defaultForm),
      toast: { show: false, color:'green', text:'', timeout: 2000 },
      valid: false,
      rules: {
        required: v => !!v || 'Required.',
        min: v => v && v.length >= 8 || 'Min 8 characters',
        maxnik: v => v && v.length <= 20 || 'Max 20 characters',
        emailRules: v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
      },
      uploadPercentage: 0,
      isupLoading: false,
      isLoading: false,
      menuSelbdate: false,
      fileUpload: null,
      dialogConfirm: false,
      listRole: [],
      listGender: [
        { text: 'Laki-laki', value: 'Pria'},
        { text: 'Perempuan', value: 'Wanita'}
      ],
      selectRole:'',
      selectGender:'',
      attachment: {
        name: '',
        file: null,
        url: ''
      },
      myemail: '',
      showPass: false,
      passwordType: 'password',
      password: '',
      passwordAgain: '',
      intIDuser: 0
    }
  },
  created () {
    this.getRole()
    if (this.isEdit) {
      const id = this.$route.params && this.$route.params.id
      this.fetchData(id)
    }
    else {
      this.postForm = Object.assign({})
      this.selectGender = 'Pria'
      this.selectRole = 2 // default authenticate
    }
  },
  destroyed () {
  },
  mounted () {
    // var now = moment().format('YYYY-MM-DD')
  },
   computed: {
    passwordConfirmationRule() {
      return () => (this.password === this.passwordAgain) || 'Password must match'
    }
  },
  methods: {
    goBack () {
      this.$router.go(-1)
    },
    getRole() {
      const vm = this
      getListRole().then(response => {
        var data = response.data.roles
        data.forEach(element => {
          var temp = { value: element.id, text: element.name }
          vm.listRole.push(temp)
        })
      })
      // console.log(vm.listRole)
    },
    resetForm () {
      this.$refs.form.reset()
    },
    onFileChange () {
      const file = this.attachment.file
      this.attachment.url = URL.createObjectURL(file)
    },
    saveAddForm () {
      const vm = this
      vm.postForm.role = vm.selectRole
      vm.postForm.gender = vm.selectGender
      
      if(vm.$refs.form.validate()){
        // console.log(vm.postForm)

        this.password = this.password.trim()
        if (this.password == '') {
          delete this.postForm.password
        } else {
          this.postForm.password = this.password
        }
        if (moment(this.postForm.birthdate).isValid()) {
          this.postForm.birthdate = moment(this.postForm.birthdate).format('YYYY-MM-DD')
        }else{
          this.postForm.birthdate = '1970-01-01'
        }
        if (this.myemail === this.postForm.email) {
          delete this.postForm.email
        } else {
          this.postForm.email = this.myemail
        }
        delete this.postForm.id
        
        vm.dialogConfirm = true
      }
      else{
        vm.toast = {
          show: true, color:'red', text: 'Form still has an error!', timeout: 2000
        }
      }
    },
    saveEditForm () {
      const vm = this
      vm.postForm.role = vm.selectRole
      vm.postForm.gender = vm.selectGender

      if(vm.$refs.form.validate()){
        // console.log(vm.postForm)

        this.password = this.password.trim()
        if (this.password == '') {
          delete this.postForm.password
        } else {
          this.postForm.password = this.password
        }
        if (moment(this.postForm.birthdate).isValid()) {
          this.postForm.birthdate = moment(this.postForm.birthdate).format('YYYY-MM-DD')
        }else{
          this.postForm.birthdate = '1970-01-01'
        }
        if (this.myemail === this.postForm.email) {
          delete this.postForm.email
        } else {
          this.postForm.email = this.myemail
        }
        vm.dialogConfirm = true
      }
      else{
        vm.toast = {
          show: true, color:'red', text: 'Form still has an error!', timeout: 2000
        }
      }
    },
    
    fetchData (id) {
      const vm = this
      vm.isLoading = true
      getUserByID(id).then(response => {
        vm.postForm = response.data
        // console.log(vm.postForm)

        delete vm.postForm.created_at
        delete vm.postForm.updated_at

        vm.myemail =vm.postForm.email

        if (vm.postForm.avatar) {
          vm.attachment.url = _GLOBAL.URLBACKEND + vm.postForm.avatar.url
          vm.attachment.name = vm.postForm.avatar.name
        }

        if (moment(vm.postForm.birthdate).isValid()) {
          vm.postForm.birthdate = moment(vm.postForm.birthdate).format('YYYY-MM-DD')
        }else{
          vm.postForm.birthdate = '1970-01-01'
        }

        if(vm.postForm.role){
          vm.selectRole = vm.postForm.role.id ? vm.postForm.role.id : 2
          // console.log(vm.selectRole)
        }
       
        vm.selectGender = vm.postForm.gender ? vm.postForm.gender : 'Pria'
 
        vm.isLoading = false
      }).catch(err => {
        // console.log(err)
        vm.isLoading = false
      })
    },
    insertData () {
      const vm = this
      vm.dialogConfirm = false

      // console.log('insert:',vm.postForm)
      vm.isLoading = true
      insertUser(vm.postForm).then(response => {
        var data = response.data
        vm.postForm = data
        
        if (vm.attachment.file !== null) {
          vm.uploadAttachment()
        }else {
          vm.$router.push('/pgw/user')
        }

        // Set parameter to 1
        getSettingParamEofficeData().then(response => {
          var data = response.data
          if(data){
            var idParam = data[0].id
            var param = {
              value: 100 //100 times = kira2 jumlah pegawai login
            }
            updateSettingParam(param, idParam).then(respupd => {
              // console.log(respupd.data)
            }).catch(error => {
              // console.log(error)
            })
          }
        }).catch(error => {
          // console.log(error)
        })

        vm.isLoading = false
        vm.toast = {
          show: true, color:'green', text: 'Submit Data BERHASIL.', timeout: 2000
        }
      })
      .catch(err => {
        // console.log(err)
        vm.isLoading = false
        vm.toast = {
          show: true, color:'red', text: 'Submit Data GAGAL, silakan dicoba kembali.', timeout: 2000
        }
      })
    },
    updateData () {
      const vm = this
      vm.dialogConfirm = false
      var now = moment().add(7, 'hours').format('YYYY-MM-DD HH:mm:ss')
      var idb = vm.postForm.id
      // console.log('id Data: ', idb)
      vm.isLoading = true
      // console.log('update:',vm.postForm)
      updateUser(vm.postForm, idb).then(response => {
        // console.log('Data', response.data)
        var data = response.data
        vm.postForm = data

        if (vm.attachment.file !== null) {
          vm.uploadAttachment()
        }
        else {
          vm.$router.push('/pgw/user')
        }

        // Set parameter to 1
        getSettingParamEofficeData().then(response => {
          var data = response.data
          if(data){
            var idParam = data[0].id
            var param = {
              value: 1
            }
            updateSettingParam(param, idParam).then(respupd => {
              // console.log(respupd.data)
            }).catch(error => {
              // console.log(error)
            })
          }

        }).catch(error => {
          // console.log(error)
        })

        vm.isLoading = false
        vm.toast = {
          show: true, color:'green', text: 'Update Data BERHASIL.', timeout: 2000
        }
      })
      .catch(err => {
        // console.log(err)
        vm.isLoading = false
        vm.toast = {
          show: true, color:'red', text: 'Update Data GAGAL, silakan dicoba kembali.', timeout: 2000
        }
      })
    },
    beforeFileUpload (file) {
      
      var isFormatOK = false
      if (file.type === 'image/jpeg' || file.type === 'image/png') {
        isFormatOK = true
      }
      const isLt2M = file.size / 1024 / 1024 < 10
      if (!isFormatOK) {
        this.$message.error('Avatar picture must be JPG format!')
      }
      if (!isLt2M) {
        this.$message.error('Avatar picture size can not exceed 10MB!')
      }
      return isFormatOK && isLt2M
    },
    filesChange (uploadfile) {
      this.attachment.file = uploadfile[0]
      // console.log(this.attachment.file)
    },
    addAttachment (file, fileList) {
      this.attachment.file = file
      // console.log(this.attachment.file)
    },
    uploadAttachment () {
      const vm = this
      // console.log('uploadAttachment',vm.attachment)
      if (vm.attachment.file) {
        var file = vm.attachment.file
        if (vm.beforeFileUpload(file)) {
          if (vm.postForm.id !== 0) {
            vm.uploadFileAttachment(vm.postForm.id, file)
          }
          else {
            vm.$router.push('/pgw/user')
          }
        }
      }
    },
    uploadFileAttachment (refID, file) {
      const vm = this
      // console.log('uploadFileAttachment')
      var token = localStorage.getItem('jwt')
      if (token.length === 0) return null

      const bodyFormData = new FormData()
      bodyFormData.append('refId', refID)
      bodyFormData.append('field', 'avatar')
      bodyFormData.append('ref', 'user')
      bodyFormData.append('source', 'users-permissions')
      bodyFormData.append('path', 'user/avatar')
      // Add each file upload to the form data
      bodyFormData.append('files', file, file.name)
      // console.log(file.name)
      var uri = _GLOBAL.URLBACKEND + '/upload'
      
      vm.isupLoading = true
      vm.uploadPercentage = 0
      axios.post(uri,
        bodyFormData,
        {
          headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${token}`
          },
          onUploadProgress: function( progressEvent ) {
            vm.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ))
          }.bind(vm)
        }
      ).then(function(response){
        // console.log('SUCCESS!!');
        // console.log(response.data)

        vm.isupLoading = false
        vm.toast = {
          show: true, color:'green', text: 'File Upload telah BERHASIL tersimpan.', timeout: 2000
        }
        vm.$router.push('/pgw/user')
      })
      .catch(function(err){
        // console.log('FAILURE!!');
        // console.log(err)
          vm.isupLoading = false
          vm.toast = {
            show: true, color:'red', text: 'File Upload GAGAL tersimpan, silakan dicoba kembali.', timeout: 2000
          }
      });
    }
  }
}

</script>
